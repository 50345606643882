import { useState, useEffect } from 'react';

const useSplash = (delay = 500) => {
  const [showSplash, setShowSplash] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowSplash(false);
    }, delay);
  }, [delay]);

  return { showSplash };
}

export default useSplash;