const Splash = () => {    
        return (
        <div className="splash">
            <div className="splash__logo">
              <h1>SPLASH</h1>
            </div>
        </div>
        );
    }

    export default Splash;