import {
  CardList,
  CardChecklist,
  CardHeading,
  CartDashFill,
} from "react-bootstrap-icons";

const features = [
  {
    id: 1,
    name: "Feature 1",
    icon: <CardList />,
    image: "logo512.png",
    description: "This is feature 1",
  },
  {
    id: 2,
    name: "Feature 2",
    icon: <CardChecklist />,
    description: "This is feature 2",
  },
  {
    id: 3,
    name: "Feature 3",
    icon: <CardHeading />,
    description: "This is feature 3",
  },
  {
    id: 4,
    name: "Feature 4",
    icon: <CartDashFill />,
    description: "This is feature 4",
  },
];

export default features;
