import React from "react";

import Plans from "./plans";
import { Container } from "react-bootstrap";
import BlockHeader from "../blockheader";

const Pricing = () => {
  return (
      <Container id="landingPricing">
        <BlockHeader title={"Pricing"} body={"Cool pricing options"} />
        <Plans />
      </Container>
  );
}

export default Pricing;