import { Container, Row, Col, Button } from "react-bootstrap";
import {
  Facebook,
  Google,
  Instagram,
  Mailbox,
  Phone,
  PinFill,
  Pinterest,
  Twitter,
  Whatsapp,
} from "react-bootstrap-icons";

const Contact = () => {
  return (
    <Container className="mb-5">
      <Container className="p-5">
        <div className="row text-center justify-content-center pt-5">
          <div className="col-12 col-md-7">
            <h1>Contact Us</h1>
          </div>
        </div>

        <div className="row justify-content-center pt-4">
          <div className="col-12 col-md-7">
            <form>
              <div className="row">
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Email"
                  />
                </div>
              </div>

              <div className="row mt-4">
                <div className="col">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Subject"
                  />
                </div>
              </div>

              <div className="row mt-4">
                <div className="col">
                  <textarea
                    className="form-control"
                    name="message"
                    rows="3"
                    placeholder="How can we help?"
                  ></textarea>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col text-center">
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="row-100"></div>
      </Container>

      <Container
        className="bg-dark p-2"
        style={{
          borderRadius: "15px",
          marginLeft: "auto",
          textAlign: "center",
        }}
      >
        <Row style={{ color: "white", margin: "10px" }}>
          <Col xs={6} style={{ padding: "10px" }}>
            <Phone style={{ color: "white", fontSize: "48px" }} />
            <p />
            +44 (112) 123 752
          </Col>

          <Col xs={6} style={{ padding: "10px" }}>
            <PinFill style={{ color: "white", fontSize: "48px" }} />
            <p />
            123 6th St.
            <br />
            Melbourne, FL 32904
          </Col>

          <Col xs={6} style={{ padding: "10px" }}>
            <Mailbox style={{ color: "white", fontSize: "48px" }} />
            <p />
            <p mailto="support@website.com">support@website.com</p>
          </Col>

          <Col xs={6} style={{ padding: "10px" }}>
            <Button
              style={{ backgroundColor: "green", borderColor: "green" }}
              size="lg"
              active
            >
              <a
                aria-label="Chat on WhatsApp"
                href="https://wa.me/14809132635?text=BookMaker Studio"
              >
                <Whatsapp style={{ color: "white", fontSize: "48px" }} />
              </a>
            </Button>
            <p />
            <p mailto="support@website.com">support@website.com</p>
          </Col>
        </Row>
      </Container>

      <Container className="p-5">
        <div className="row-70"></div>
        <div className="row text-center">
          <div className="col">
            <p className="h2">
              <a href="https://www.froala.com" className="mx-2">
                <Facebook />
              </a>
              <a href="https://www.froala.com" className="mx-2">
                <Twitter />
              </a>
              <a href="https://www.froala.com" className="mx-2">
                <Instagram />
              </a>
              <a href="https://www.froala.com" className="mx-2">
                <Google />
              </a>
              <a href="https://www.froala.com" className="mx-2">
                <Pinterest />
              </a>
            </p>
          </div>
        </div>
      </Container>
    </Container>
  );
};

export default Contact;
