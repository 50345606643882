import React, { useState } from "react";
import {Button, Modal, Form } from "react-bootstrap";


const ForgotPasswordModal = ({ show, handleClose, googlelogin }) => {
  const [email, setemail] = useState("cairnswm@gmail.com");

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Forgot Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={(ev) => {
                setemail(ev.target.value);
              }}
            />
            <Form.Text className="text-muted">
              If your email has been registered we will send you a link to
              update your password.
            </Form.Text>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-primary"
          onClick={() => {
            window.location.hash = "#login";
          }}
        >
          Magic Link
        </Button>
        <Button
          variant="outline-primary"
          onClick={() => {
            window.location.hash = "#login";
          }}
        >
          Return to Login
        </Button>
        <Button variant="primary">Forgot Password</Button>
        <Button
          variant="outline-primary"
          onClick={() => {
            window.location.hash = "#login/register";
          }}
        >
          Register
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ForgotPasswordModal;
