import Container from "react-bootstrap/Container";
import { Nav, Navbar, Image } from "react-bootstrap";

function Header(props) {
  const scrollToElement = (elementId) => {
    const element = document?.querySelector(`#${elementId}`);
    element.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <Navbar sticky="top" collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Container>
        <Navbar.Brand
          onClick={() => {
            scrollToElement("landingTop");
          }}
        >
          <Image src="logo192.png" width="50px" className="pe-3" />
          BookMaker Studio
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link
              onClick={() => {
                scrollToElement("landingFeatures");
              }}
            >
              Features
            </Nav.Link>
            <Nav.Link
              onClick={() => {
                scrollToElement("landingPricing");
              }}
            >
              Pricing
            </Nav.Link>
            <Nav.Link href="#pricing">Video</Nav.Link>
            {/* <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown> */}
          </Nav>
          <Nav>
            {/* <Nav.Link href="#deets">More deets</Nav.Link> */}
            <Nav.Link eventKey={2} href="#login">
              Login
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
