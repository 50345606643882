import React from "react";
import { Card, CardGroup } from "react-bootstrap";

const planContents = [
  {
    header: "Free",
    price: 0,
    features: [
      "10 content calls",
      "1 book storage",
      "Email support",
      "Help center access",
    ],
    buttonLabel: "Sign up for free",
    outline: true,
  },
  {
    header: "Pro",
    price: 15,
    features: [
      "100 content calls",
      "10 books storage",
      "Priority email support",
      "Help center access",
    ],
    buttonLabel: "Get started",
    outline: false,
  },
  {
    header: "Enterprise",
    price: 29,
    features: [
      "300 Content calls",
      "Unlimited books storage",
      "Phone and email support",
      "Help center access",
    ],
    buttonLabel: "Contact us",
    outline: false,
  },
];

const Plan = (props) => {
  return (
    <Card style={{margin:"20px", borderRadius:"20px", border: "1px solid lightgrey"}}>
      <Card.Header>
        <Card.Title>{props.header}</Card.Title>
      </Card.Header>
      <Card.Body>
        <Card.Title>
          ${props.price}
          <small className="text-muted">/ mo</small>
        </Card.Title>
        <ul className="list-unstyled mt-3 mb-4">
          {props.features.map((feature, i) => (
            <li key={i}>{feature}</li>
          ))}
        </ul>
        <button
          className={`btn btn-lg btn-block ${
            props.outline ? "btn-outline-primary" : "btn-primary"
          }`}
          type="button"
        >
          {props.buttonLabel}
        </button>
      </Card.Body>
    </Card>
  );
};

const Plans = () => {
  const plans = planContents.map((obj, i) => {
    return (
      <Plan
        key={obj.header}
        header={obj.header}
        price={obj.price}
        features={obj.features}
        buttonLabel={obj.buttonLabel}
        outline={obj.outline}
      />
    );
  });

  return <CardGroup>{plans}</CardGroup>;
};

export default Plans;
