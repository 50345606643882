import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import { useGoogleLogin } from "@react-oauth/google";
import { useJwt } from "react-jwt";
import { Google } from "react-bootstrap-icons";
import { AuthenticationContext } from "../context/authContext";
import { useContext } from "react";
import { useLocation } from "../hooks/useLocation";
import RegisterModal from "./registerModal";
import ForgotPasswordModal from "./forgotPasswordModal";
import MagicLinkModal from "./magiclinkModal";

const LoginForm = ({ show, setShow }) => {
  const { hash } = useLocation();

  const [profile, setProfile] = useState();
  const [, setUser] = useState();

  const [token, ] = useState();

  const { decodedToken } = useJwt(token);

  const { setAccessToken, login } = useContext(AuthenticationContext);

  const [email, setemail] = useState("cairnswm@gmail.com");
  const [password, setpassword] = useState("xyz");

  console.log("Render Profile", profile);
  console.log("Render Token", decodedToken);

  useEffect(() => {
    if (decodedToken) {
      console.log("DECODED TOKEN", decodedToken);
      console.log("PICTURE", decodedToken.picture);
      setProfile({
        email: decodedToken.email,
        family_name: decodedToken.family_name,
        given_name: decodedToken.given_name,
        id: decodedToken.sub,
        locale: "en-GB",
        name: decodedToken.name,
        picture: decodedToken.picture,
        verified_email: decodedToken.verified_email,
      });
    }
  }, [decodedToken]);

  const googleLoginSuccess = (response) => {
    console.log("googleLoginSuccess RESPONSE", response);
    setUser(response);

    setAccessToken(response.access_token);
    window.location.hash = "main";
  };

  const googlelogin = useGoogleLogin({
    onSuccess: (tokenResponse) => googleLoginSuccess(tokenResponse),
  });
  const handleClose = () => setShow(false);

  if (hash.includes("forgot")) {
    return <ForgotPasswordModal show={show} handleClose={handleClose} />;
  }

  if (hash.includes("register")) {
    return (
      <RegisterModal
        show={show}
        handleClose={handleClose}
        googlelogin={googlelogin}
      />
    );
  }

  if (hash.includes("magic")) {
    return (
      <MagicLinkModal
        show={show}
        handleClose={handleClose}
        googlelogin={googlelogin}
      />
    );
  }

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Login</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={(ev) => {
                setemail(ev.target.value);
              }}
            />
            <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              value={password}
              onChange={(ev) => {
                setpassword(ev.target.value);
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Form.Check type="checkbox" label="Remember me" />
          </Form.Group>
          <div className="d-grid gap-2">
            <Button variant="outline-primary" onClick={() => googlelogin()}>
              Sign in with Google <Google />{" "}
            </Button>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-primary"
          onClick={() => {
            window.location.href = "#login/magic";
          }}
        >
          Magic Link
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            login(email, password);
          }}
        >
          Login
        </Button>
        <Button
          variant="outline-primary"
          onClick={() => {
            window.location.hash = "#login/forgot";
          }}
        >
          Forgot Password
        </Button>
        <Button
          variant="outline-secondary"
          onClick={() => {
            window.location.hash = "#login/register";
          }}
        >
          Register
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LoginForm;
