import React, { useState } from "react";
import {Button, Modal, Form } from "react-bootstrap";


const MagicLinkModal = ({ show, handleClose, googlelogin }) => {
  const [email, setemail] = useState("cairnswm@gmail.com");

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Magic Link</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={(ev) => {
                setemail(ev.target.value);
              }}
            />
            <Form.Text className="text-muted">
              If your email exists in our records we will send an email that contains a link that will automatically log you in. The link remains valid for 24 hours.
            </Form.Text>
          </Form.Group>

          
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-primary"
          onClick={() => {
            window.location.hash = "#login";
          }}
        >
          Return to Login
        </Button>
        <Button
          variant="outline-primary"
          onClick={() => {
            window.location.hash = "#login/forgot";
          }}
        >
          Forgot Password
        </Button>
        <Button variant="primary">Register</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MagicLinkModal;
