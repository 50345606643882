import { useContext } from "react";
import { Container, Image, Dropdown, Nav, Navbar, NavItem, NavLink } from "react-bootstrap";

import { AuthenticationContext } from "../context/authContext";

function Header(props) {
  const { logout, user } = useContext(AuthenticationContext);

  const scrollToElement = (elementId) => {
    const element = document?.querySelector(`#${elementId}`);
    element.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <Navbar sticky="top" collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Container>
        <Navbar.Brand
          onClick={() => {
            scrollToElement("landingTop");
          }}
        >
          <Image src="logo192.png" width="50px" className="pe-3" />
          PWA Application
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link
              href="#main/opt1"
            >
              Option One
            </Nav.Link>
            <Nav.Link
              onClick={() => {
                scrollToElement("landingPricing");
              }}
            >
              Pricing
            </Nav.Link>
            <Nav.Link href="#main/about">about</Nav.Link>
          </Nav>
          <Nav>
            {/* <Nav.Link href="#deets">More deets</Nav.Link> */}
            <Nav.Link
              eventKey={2}
              href="#Home"
              onClick={() => {
                logout();
              }}
            >
              logout
            </Nav.Link>
            {user && user.picture && (
              <Dropdown as={NavItem} align={"end"}>
                <Dropdown.Toggle as={NavLink}>
                  <Image
                    src={user.picture}
                    roundedCircle
                    width="40px"
                    referrerPolicy="no-referrer"
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="#main/profile">Profile</Dropdown.Item>
                  <Dropdown.Item
                    href="#Home"
                    onClick={() => {
                      logout();
                    }}
                  >
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
