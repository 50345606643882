import { useState, createContext, useEffect, useCallback } from "react";
import { googleLogout } from "@react-oauth/google";

export const AuthenticationContext = createContext();

export const AuthenticationProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useState();
  const [user, setUser] = useState();

  const getGoogleUser = useCallback(async () => {
    if (accessToken) {
      console.log("access_token", accessToken);
      await fetch(
        `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${accessToken}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((res) => {
          console.log("Context: GOOGLE LOGIN RESPONSE", res);
          setUser(res);
        })
        .catch((err) => console.log(err));
    }
  }, [accessToken]);

  useEffect(() => {
    if (accessToken) {
      getGoogleUser(accessToken);
    }
  }, [accessToken, getGoogleUser]);

  const logout = () => {
    setAccessToken(null);
    setUser(null);
    googleLogout();
  };

  const login = (email, password) => {
    if (email === "cairnswm@gmail.com" && password === "xyz") {
      setUser({
        email: "cairnswm@gmail.com",
        family_name: "Cairns",
        given_name: "William",
        id: "104135398474925362318",
        locale: "en-GB",
        name: "William Cairns",
        picture:
          "https://lh3.googleusercontent.com/a/AEdFTp7U2hhTvVPI90GCRtLKuZlYrohThOqIDJgrFiJ3OA=s96-c",
        verified_email: true,
      });
    window.location.hash = "main";
    }
  };

  return (
    <AuthenticationContext.Provider
      value={{
        accessToken,
        setAccessToken,
        login,
        logout,
        user,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

export default AuthenticationProvider;
