import React, { useState } from "react";
import {Button, Modal, Form } from "react-bootstrap";
import { Google } from "react-bootstrap-icons";


const RegisterModal = ({ show, handleClose, googlelogin }) => {
  const [email, setemail] = useState("cairnswm@gmail.com");
  const [password, setpassword] = useState("xyz");
  const [confirm, setconfirm] = useState("xyz");

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Register</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={(ev) => {
                setemail(ev.target.value);
              }}
            />
            <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              value={password}
              onChange={(ev) => {
                setpassword(ev.target.value);
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              value={confirm}
              onChange={(ev) => {
                setconfirm(ev.target.value);
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Form.Check type="checkbox" label="Remember me" />
          </Form.Group>
          <div className="d-grid gap-2">
            <Button variant="outline-primary" onClick={() => googlelogin()}>
              Register with Google <Google />{" "}
            </Button>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-primary"
          onClick={() => {
            window.location.hash = "#login";
          }}
        >
          Return to Login
        </Button>
        <Button
          variant="outline-primary"
          onClick={() => {
            window.location.hash = "#login/forgot";
          }}
        >
          Forgot Password
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            window.location.hash = "#login/register";
          }}
        >
          Register
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RegisterModal;
