import Summary from "../landing/about/about";
import Features from "../landing/features/features";
import Pricing from "../landing/pricing/pricing";
import Video from "../landing/video/video";

const About = () => {
  return (
    <div className="landing">
      <Summary />
      <Features />
      <Pricing />
      <Video />
    </div>
  );
};

export default About;
