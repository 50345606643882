import React, { useContext } from "react";
import AddToHomeScreen from "@ideasio/add-to-homescreen-react";
import "./App.scss";
import useSplash from "./hooks/useSplash";
import { useLocation } from "./hooks/useLocation";
import Landing from "./landing/landing";
import LoginForm from "./login/login";
import Splash from "./splashpage/splash";
import Main from "./app/main";
import { AuthenticationContext } from "./context/authContext";

function App() {
  const { showSplash } = useSplash();
  const { hash } = useLocation(["id"]);

  const { user } = useContext(AuthenticationContext);

  if (showSplash) {
    return <Splash />;
  }

  return (
    <div className="App">
      <AddToHomeScreen
        debug="true"
        appId="bookmaker.studio.pwa"
        startAutomatically={true}
        startDelay={0}
        lifespan={300}
        displayPace={0}
        customPromptContent={{
          title:
            "Do you want to install the BookMaker Studio on your homescreen?",
          cancelMsg: "No",
          installMsg: "Yes, sure!",
          guidanceCancelMsg: "Dismiss",
          src: "./logo192.png",
        }}
        onShow={() => {
          console.log("Show Add to HomeScreen");
        }}
      />

      {(!user || hash === "Home" || hash === "login" || hash === "") && (
        <Landing />
      )}
      {user && (hash === "main" || hash.includes("main") || hash === "") && (
        <Main />
      )}

      {hash.includes("login") ? (
        <LoginForm
          show={true}
          setShow={() => {
            window.location.hash = "Home";
          }}
        />
      ) : null}
    </div>
  );
}

export default App;
