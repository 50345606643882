import { Container, Row, Col, Image, Button } from "react-bootstrap";
import BlockHeader from "../blockheader";

const About = () => {
  return (
    <Container className={"pt-3"} id={"landingTop"}>
      <BlockHeader
        title="BookMaker Studio"
        body={`Simple starter template for BookMaker Studio web app using React,
        Bootstrap, and PWA. Add to home screen for phones, and google social for
        login. Yay`}
      />

      <div className="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5">
        <Button
          variant="primary"
          onClick={() => {
            window.location.hash = "login";
          }}
        >
          Login
        </Button>
        <Button
          variant="outline-primary"
          onClick={() => {
            window.location.hash = "login/register";
          }}
        >
          Register
        </Button>
      </div>
      <Container>
        <Row>
          <Col></Col>
          <Col>
            <Image
              fluid
              className="mx-auto"
              src="logo512.png"
              style={{ align: "center", maxWidth: "75vw" }}
            />
          </Col>
          <Col></Col>
        </Row>
      </Container>
    </Container>
  );
};

export default About;
