import About from "./about/about";
import Features from "./features/features";
import Header from "./header";
import Pricing from "./pricing/pricing";
import Video from "./video/video";
import Contact from "../blocks/contact";
import FeaturesJson from "../config/features.config";

const Landing = () => {
  return (
    <div className="landing">
      <Header />
      <About />
      <Features features={FeaturesJson} />
      <Pricing />
      <Video />
      <Contact />
    </div>
  );
};

export default Landing;
