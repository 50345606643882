import { Container, Card, Row, Col } from "react-bootstrap";
import BlockHeader from "../blockheader";

/* Array of Feature
{
    id: 1,
    name: "Feature 1",
    icon: <CardList />, // From react-bootstrap-icons
    image: "logo512.png", // Optional
    description: "This is feature 1",
  }
*/

const Feature = ({ feature }) => {
  return (
    <Col key={feature.id}>
      <Card>
        <Card.Header>
          <Card.Title>
            {feature.icon} <span style={{marginLeft:"20px"}}>{feature.name}</span>
          </Card.Title>
        </Card.Header>
        <Card.Body>
          {feature.image ? (
            <Row>
              <Col xs={4}>
                <Card.Img variant="top" src={feature.image} />
              </Col>
              <Col xs={8}>
                <Card.Text>{feature.description}</Card.Text>
              </Col>
            </Row>
          ) : (
            <Card.Text>{feature.description}</Card.Text>
          )}
        </Card.Body>
      </Card>
    </Col>
  );
};

const Features = ({ features }) => {
  return (
    <Container id={"landingFeatures"}>
      <BlockHeader title={"Features"} body={"These are the cool features"} />
      <Row xs={1} md={2} className="g-4">
        {features.map((feature, idx) => (
          <Feature feature={feature} key={feature.id} />
        ))}
      </Row>
    </Container>
  );
};
export default Features;
