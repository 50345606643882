import Header from "./header";
import { useLocation } from "../hooks/useLocation";
import Profile from "./profile/profile";
import About from "./about";

const Main = () => {
  
  const { hash } = useLocation(["id"]);
  return (
    <div className="Main">
      <Header />

      {hash === "main" && <h1> Main </h1>}
      {hash === "main/about" && <About /> }
      {hash === "main/profile" && <Profile />}
    </div>
  );
};

export default Main;
