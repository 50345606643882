import { Container } from "react-bootstrap";
import BlockHeader from "../blockheader";

const Video = () => {
  return (
    <Container className="mb-5">
      <BlockHeader title="Details" body="A short look at what we do." />
      <div className="ratio ratio-16x9">
        <iframe
          src="https://www.youtube.com/embed/vlDzYIIOYmM"
          title="YouTube video"
          allowFullScreen
        ></iframe>
      </div>
    </Container>
  );
};

export default Video;
